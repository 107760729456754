// export const API_URL = "https://api.getfreadom.com/api/";

// const isProdEnv = () => ['web', 'staging'].includes(window.location?.hostname?.split('.')?.[0]);
const isProdEnv = () => window.location?.hostname?.split(".")?.[0];

export const API_URL =
  isProdEnv() === "web"
    ? "https://api.getfreadom.com/api/"
    : isProdEnv() === "staging"
    ? "https://stage.api.getfreadom.com/api/"
    : "https://stage.api.getfreadom.com/api/";
export const F_LIVE_API_URL =
  isProdEnv() === "web" || isProdEnv() === "staging"
    ? "https://optimus.getfreadom.com/"
    : "https://optimus-alpha.getfreadom.com/";
