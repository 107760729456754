import { initialState } from "./state";
import { storage as LocalStorage } from "services/config/storage";

export const linkschoolcode = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SCHOOL_CODE_MODAL":
      return {
        ...state,
        schoolCodeModal: action.data,
      };
    case "SET_SCHOOL_CODE_DATA":
      return {
        ...state,
        ...action.data,
      };
    case "SET_NEXT_GRADE":
      return {
        ...state,
        nextGrade: action.data,
      };
    case "SET_SCHOOL_CLASS_DATA":
      return {
        ...state,
        schoolSectionData: action.data,
      };
    case "SET_LINK_SCHOOL_CODE_MODE":
      return {
        ...state,
        currentMode: action.data,
      };
    case "SET_CMF_ATTEMPT":
      const startDate = "2024-04-01";
      const endDate = "2025-03-31";
      const orfStartDate = new Date(
        LocalStorage.fetch.defaultChild()?.school_details?.orf_start_date
      );
      const orfCloseDate = new Date(
        LocalStorage.fetch.defaultChild()?.school_details?.orf_close_date
      );
      const today = new Date();
      // const todayDate = `${today.getFullYear()}-${String(
      //   today.getMonth() + 1
      // ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

      let cmfRecords = []
      let testTaken = false
      let data = action.data?.forEach((cmfrecord) => {
        if (
          Date.parse(cmfrecord?.submitted) >= Date.parse(startDate) &&
          Date.parse(cmfrecord?.submitted) <= Date.parse(endDate)
        ) {

          if (cmfrecord?.source === "ELG_Web" || cmfrecord?.source === "ELG_app") {
            cmfRecords.push(cmfrecord)
          }
        }

        const isTodayInRange = new Date(cmfrecord?.submitted) >= orfStartDate && new Date(cmfrecord?.submitted) <= orfCloseDate;

        if(isTodayInRange){
          testTaken = true
        }

        // const differenceInMilliseconds =
        //   today.getTime() - new Date(cmfrecord?.submitted).getTime();
        // const differenceInDays = Math.abs(
        //   differenceInMilliseconds / (1000 * 3600 * 24)
        // );

        // if(differenceInDays <=15){
        //   testTaken=true
        // }
      });
      return {
        ...state,
        cmfAttempt: cmfRecords.length,
        testTaken:testTaken
      };
    default:
      return state;
  }
};
